.custom-file-upload {
    background-color: rgba(0,0,0,0.5);
    color: white;
    padding: 10px 20px;
    font-family: sans-serif;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
    transition: background-color 0.3s;
  }

.custom-file-upload:hover {
    background-color: #2D7AFE;
}

#actual-btn {
    display: none;
}
